import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { getPostObj } from "../utils/utils"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageSlider from "../components/ImageSlider/ImageSlider"

import SEO from "../components/seo"
import Image from "../components/Image"
import Text from "../components/Text"
import { Section, Columns } from "../components/Containers"
import { CallToAction } from "../components/CTA"

const SingleDoctor = ({ pageContext, location, data }) => {
  const postObj = getPostObj(
    pageContext,
    data.allDoctorsJson.nodes[0],
    data.allSpanishDoctorsJson.nodes[0]
  )
  const { post, language } = postObj

  const docImages = post.facts
    .filter(obj => obj.fact.image)
    .map(obj => obj.fact.image)

  return (
    <SharedStateProvider>
      <Layout
        language={language}
        pageHeading={post.heading}
        footerColorBack
        pageTitle="doctor-page">
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />
        <Section className="mb-0-mobile">
          <Columns className="is-desktop" sideColumnsSize={1}>
            <div className="column is-10">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.aboutSection.youtube}`}
                controls
                playing
                language={"en"}
                overVideo
                buttonClass="contained dr-about-theater-btn">
                <Image publicId={post.aboutSection.image} />
              </TheaterVideo>
              <Text text={post.aboutSection.body} />
            </div>
            <div className="column is-2"></div>
            <div className="column is-10">
              <div className="doctor-facts">
                {post.facts.map(({ fact }, i) => (
                  <Text key={i} text={fact.text} />
                ))}

                <div className="doctor__gallery">
                  {docImages.length === 1 && <Image publicId={docImages[0]} />}
                  {docImages.length >= 2 && (
                    <ImageSlider
                      className="doctor__slider"
                      useDots
                      images={docImages}
                    />
                  )}
                </div>
              </div>
            </div>
          </Columns>
        </Section>

        <CallToAction
          colorBack
          heading={post.learnMore.heading}
          blurb={post.learnMore.blurb}
          buttons={post.learnMore.buttons}
        />
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query doctorPages(
    $title: String!
    $gmbIds: [String]
    $locations: [String]
    $locationReviews: String
  ) {
    backButton: allUniquePagesJson(filter: { template: { eq: "about-us" } }) {
      nodes {
        title
      }
    }
    allDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        gmbIds
        hasProfileImage
        hasBodyImages
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          image
          youtube
        }
        facts {
          fact {
            text
            image
          }
        }
        learnMore {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
    allSpanishDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        hasProfileImage
        hasBodyImages
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        educationResidency {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        hobbiesInterests {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        certifications {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        learnMore {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
    allLocationMatches: allSocialJson(
      filter: {
        data: {
          rating: { gte: 4 }
          platformId: { in: $gmbIds }
          totalReviews: { gt: 0 }
        }
      }
    ) {
      nodes {
        data {
          rating
          totalReviews
          url
        }
      }
    }
    review: allSocialJson(
      filter: { data: { platformId: { eq: $locationReviews } } }
    ) {
      nodes {
        data {
          rating
          totalReviews
          url
        }
      }
    }
    allSocials: allSocialJson {
      nodes {
        data {
          rating
          totalReviews
          url
          platformId
        }
      }
    }
    matchingLocations: allLocationsJson(
      filter: { pageData: { title: { in: $locations } } }
    ) {
      nodes {
        locationData {
          gmbId
        }
      }
    }
  }
`

SingleDoctor.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({
    allDoctorsJson: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          heading: PropTypes.string,
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string
        })
      ).isRequired
    }).isRequired
  }).isRequired
}

export default SingleDoctor
